<template>
  <div class="watchpk">
    <div class="header">
      <div class="left">
        <div class="lefti" v-for="(item, index) in selectedBoxes" :key="index">
          <img :src="item.img" alt />
          <div>{{ item.price }}</div>
        </div>
      </div>
      <div class="right">
        <img src="@/assets/images/waitpk/return.png" alt @click="goBack" />
      </div>
    </div>
    <div class="main">
      <div class="maini" v-for="(item, index) in players" :key="index + 'new'">
        <img
          v-if="item.portrait == null"
          src="@/assets/images/user/head.jpg"
          alt
        />
        <img v-else :src="item.portrait" alt />
        <div
          style="
            width: 180px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          "
        >
          {{ item.name }}
        </div>
      </div>
      <div
        class="maini"
        v-for="(item, index) in amount"
        :key="index"
        v-show="isshow"
      >
        <div class="immediate" @click="joinPK"></div>
      </div>
    </div>
    <!-- 手机端样式 -->
    <div class="phone-header">
      <div class="dating" @click="goBack">
        <img src="@/assets/images/pk/fanhui.png" alt />
        <div>返回大厅</div>
      </div>
      <div class="huihe">回合数:{{ selectedBoxes.length }}</div>
    </div>
    <div class="phone-main">
      <div class="pml">已选物品{{ selectedBoxes.length }}</div>
      <div class="pmr">
        <div style="color: #fff; margin-right: 8px">共计:</div>
        <img src="@/assets/images/pk/jinbi.png" alt />
        <div>{{ totalPrice }}</div>
      </div>
    </div>
    <div class="pw">
      <div
        class="pwi"
        v-for="(item, index) in selectedBoxes"
        :key="index + 'z'"
        @click="selected(item.bId)"
      >
        <img :src="item.img" alt />
        <div>{{ item.price }}</div>
      </div>
      <div class="psd" v-show="show == true">
        <div
          class="psdi"
          v-for="(item, index) in ornament"
          :key="index"
          @click="closetan"
        >
          <img :src="item.img" alt class="pdi" />
          <div class="pdn">{{ item.name }}</div>
          <div class="pdni">
            <img src="@/assets/images/pk/jinbi.png" alt />
            <div>{{ item.openPrice }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="wp">
      <div class="wpi" v-for="(item, index) in players" :key="index + 'nw'">
        <img
          v-if="item.portrait == null"
          src="@/assets/images/user/head.jpg"
          alt
        />
        <img v-else :src="item.portrait" alt />
        <div class="username">{{ item.name }}</div>
      </div>
      <div
        class="wpj"
        v-for="(item, index) in amount"
        :key="index + 'nbw'"
        v-show="isshow"
      >
        <img src="@/assets/images/waitpk/join.png" alt @click="joinPK" />
      </div>
    </div>
  </div>
</template>

<script>
import { getRoomList, joInPk, boxOrnament } from "../../api/index";
import { mapState } from "vuex";

export default {
  inject: ["reload"],
  data() {
    return {
      status: 0,
      joinType: 0,
      amount: [],
      //选择的箱子
      selectedBoxes: [],
      //总价
      totalPrice: "",
      //玩家
      players: [],
      ready: [],
      isshow: true,
      time1: null,
      show: false,
      ornament: [],
    };
  },
  computed: {
    ...mapState(["userId"]),
  },
  created() {
    //改变浏览器默认返回上一页
    function pushHistory() {
      window.history.pushState({}, "index", "#");
    }
    //执行代码
    pushHistory();
    //添加监听事件，监听后退，前进
    window.addEventListener(
      "popstate",
      function () {
        window.location.href = "/Pk";
        // this.$router.push({ name: "Pk" });
      },
      false
    );
    //到这都是浏览器返回
    let id = this.$route.params.id;
    this.time1 = setInterval(() => {
      getRoomList(id).then((res) => {
        this.selectedBoxes = res.data.data.boxList;
        this.totalPrice = res.data.data.ttFightAgainst.payCoin;
        this.joinType = res.data.data.ttFightAgainst.joinType;
        this.players = res.data.data.users;
        //双人房
        if (this.joinType == 1) {
          //一个人
          if (this.players.length == 1) {
            this.amount = ["1"];
            this.ready = ["1"];
          } else {
            //满人
            this.isshow = false;
            this.ready = ["1", "2"];
            setTimeout(() => {
              this.$router.push(`/watch/${id}`);
            }, 1000);
          }
        }
        //三人房
        else if (this.joinType == 2) {
          //一名玩家
          if (this.players.length == 1) {
            this.amount = ["1", "2"];
            this.ready = ["1"];
          }
          //两名玩家
          else if (this.players.length == 2) {
            this.amount = ["1"];
            this.ready = ["1", "2"];
          } else {
            this.isshow = false;
            this.ready = ["1", "2", "3"];
            setTimeout(() => {
              this.$router.push(`/watch/${id}`);
            }, 1000);
          }
        }
        //四人房
        else if (this.joinType == 3) {
          //一名玩家
          if (this.players.length == 1) {
            this.amount = ["1", "2", "3"];
            this.ready = ["1"];
          }
          //两名玩家
          else if (this.players.length == 2) {
            this.amount = ["1", "2"];
            this.ready = ["1", "2"];
          }
          //三名玩家
          else if (this.players.length == 3) {
            this.amount = ["1"];
            this.ready = ["1", "2", "3"];
          } else {
            this.isshow = false;
            this.ready = ["1", "2", "3", "4"];
          }
        }
      });
    }, 2500);
  },
  mounted() {
    let id = this.$route.params.id;
    getRoomList(id).then((res) => {
      this.selectedBoxes = res.data.data.boxList;
      // console.log(this.selectedBoxes);
      this.totalPrice = res.data.data.ttFightAgainst.payCoin;
      this.joinType = res.data.data.ttFightAgainst.joinType;
      this.players = res.data.data.users;
      //双人房
      if (this.joinType == 1) {
        if (this.players.length == 1) {
          this.amount = ["1"];
          this.ready = ["1"];
        } else {
          this.isshow = false;
          this.ready = ["1", "2"];
        }
      }
      //三人房
      else if (this.joinType == 2) {
        //一名玩家
        if (this.players.length == 1) {
          this.amount = ["1", "2"];
          this.ready = ["1"];
        }
        //两名玩家
        else if (this.players.length == 2) {
          this.amount = ["1"];
          this.ready = ["1", "2"];
        } else {
          this.isshow = false;
          this.ready = ["1", "2", "3"];
        }
      }
      //四人房
      else if (this.joinType == 3) {
        //一名玩家
        if (this.players.length == 1) {
          this.amount = ["1", "2", "3"];
          this.ready = ["1"];
        }
        //两名玩家
        else if (this.players.length == 2) {
          this.amount = ["1", "2"];
          this.ready = ["1", "2"];
        }
        //三名玩家
        else if (this.players.length == 3) {
          this.amount = ["1"];
          this.ready = ["1", "2", "3"];
        } else {
          this.isshow = false;
          this.ready = ["1", "2", "3", "4"];
        }
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.time1);
  },
  methods: {
    goBack() {
      this.$router.push({ name: "allpk" });
    },
    selected(id) {
      this.show = true;
      boxOrnament(id).then((res) => {
        // console.log(res);
        this.ornament = res.data.rows;
      });
    },
    closeWeapon() {
      this.show = false;
    },
    //加入对战
    joinPK() {
      //先判断是不是同一个人
      let id = this.$route.params.id;
      getRoomList(id).then((res) => {
        let players = res.data.data.users;
        let flag = players.some((item) => {
          return this.userId == item.id;
        });
        if (flag == true) {
          this.$message({
            message: "您已在房间内，无需重复加入",
            type: "warning",
          });
        } else {
          joInPk(id).then((res) => {
            this.$message(res.data.msg);
            if (res.data.code == 200) {
              getRoomList(id).then((res) => {
                this.joinType = res.data.data.ttFightAgainst.joinType;
                this.players = res.data.data.users;
                //双人房
                if (this.joinType == 1) {
                  //一个玩家
                  if (this.players.length == 1) {
                    this.amount = ["1"];
                    this.ready = ["1"];
                  }
                  //满人
                  else {
                    this.isshow = false;
                    this.ready = ["1", "2"];
                    setTimeout(() => {
                      this.$router.push(`/watch/${id}`);
                    }, 2500);
                  }
                }
                //三人房
                else if (this.joinType == 2) {
                  //一名玩家
                  if (this.players.length == 1) {
                    this.amount = ["1", "2"];
                    this.ready = ["1"];
                  }
                  //两名玩家
                  else if (this.players.length == 2) {
                    this.amount = ["1"];
                    this.ready = ["1", "2"];
                  } else {
                    this.isshow = false;
                    this.ready = ["1", "2", "3"];
                  }
                }
                //四人房
                else if (this.joinType == 3) {
                  //一名玩家
                  if (this.players.length == 1) {
                    this.amount = ["1", "2", "3"];
                    this.ready = ["1"];
                  }
                  //两名玩家
                  else if (this.players.length == 2) {
                    this.amount = ["1", "2"];
                    this.ready = ["1", "2"];
                  }
                  //三名玩家
                  else if (this.players.length == 3) {
                    this.amount = ["1"];
                    this.ready = ["1", "2", "3"];
                  } else {
                    this.isshow = false;
                    this.ready = ["1", "2", "3", "4"];
                  }
                }
                this.reload();
              });
            }
          });
        }
      });
      return;
    },
    closetan() {
      this.show = false;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.watchpk {
  width: 1130px;
  height: 468px;
  margin: 35px auto 10px;
  background: url("~@/assets/images/home/mainallbg.png");
  background-size: 100% 100%;
  overflow: auto;

  .header {
    width: 1000px;
    height: 100px;
    margin: 100px auto 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      height: 100%;
      width: 800px;
      display: flex;
      justify-content: space-evenly;

      .lefti {
        width: 100px;
        height: 100px;
        text-align: center;
        font-size: 18px;
        color: #fff;

        img {
          display: block;
          width: 100%;
          height: 80px;
        }
      }
    }

    .right {
      img {
        display: block;
        width: 100px;
        height: 100px;
        margin: auto;
      }
    }
  }

  .main {
    width: 800px;
    height: 180px;
    margin: auto;
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;

    .maini {
      width: 180px;
      height: 160px;
      background-image: url("../../assets/images/watchPk/playerbg.png");
      background-size: 100% 100%;

      img {
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin: -20px auto;
      }

      div {
        text-align: center;
        font-size: 40px;
        margin-top: 40px;
        color: #fff;
      }

      .immediate {
        width: 50px;
        height: 50px;
        margin: 60px auto;
        background-image: url("../../assets/images/waitpk/join.png");
        background-size: 100% 100%;
      }
    }
  }

  .phone-header {
    display: none;
  }

  .phone-main {
    display: none;
  }

  .pw {
    display: none;
  }

  .wp {
    display: none;
  }
}

@media (max-width: 550px) {
  .watchpk {
    width: 350px;
    height: 550px;
    background-image: none;
    margin: 0px auto;

    .header {
      display: none;
    }

    .main {
      display: none;
    }

    .phone-header {
      display: flex;
      justify-content: space-between;
      width: 90%;
      height: 30px;
      margin: auto;

      .dating {
        width: 34%;
        height: 100%;
        display: flex;
        align-items: center;
        color: #00d2ff;
        font-size: 18px;

        img {
          flex: none;
          margin-right: 6px;
          width: 26px;
          height: 26px;
        }
      }

      .huihe {
        width: 30%;
        height: 100%;
        text-align: center;
        line-height: 30px;
        color: #fff;
      }
    }

    .phone-main {
      display: flex;
      width: 250px;
      height: 28px;
      margin: 10px auto;
      align-items: center;
      justify-content: space-around;

      .pml {
        flex: none;
        margin-right: 10px;
        font-size: 18px;
        color: #fff;
      }

      .pmr {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: rgba(255, 240, 0, 1);

        img {
          flex: none;
          margin-right: 4px;
          display: block;
          width: 20px;
          height: 20px;
        }
      }
    }

    .pw {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      height: 70px;
      margin: auto;
      background-image: url("../../assets/images/waitpk/bg666.png");
      background-size: 100% 100%;
      overflow: auto;
      position: relative;

      .pwi {
        width: 60px;
        height: 60px;

        img {
          display: block;
          width: 60px;
          height: 40px;
          margin: auto;
        }

        div {
          font-size: 14px;
          color: #fff;
          text-align: center;
        }
      }

      .psd {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        overflow: auto;
        display: flex;
        justify-content: space-evenly;
        backdrop-filter: blur(8px);

        .psdi {
          flex: none;
          width: 70px;
          height: 100%;

          .pdi {
            display: block;
            margin: auto;
            width: 80%;
            height: 50%;
          }

          .pdn {
            width: 80%;
            margin: auto;
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: #fff;
          }

          .pdni {
            width: 60%;
            display: flex;
            align-items: center;
            font-size: 12px;
            color: rgba(255, 240, 0, 1);
            margin: auto;

            img {
              margin-right: 4px;
              width: 13px;
              height: 13px;
            }
          }
        }
      }
    }

    .wp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 110px;
      margin: auto;

      .wpi {
        width: 48%;
        height: 100px;
        background-image: url("../../assets/images/watchPk/playerbg.png");
        background-size: 100% 100%;

        img {
          display: block;
          margin: auto;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-top: 10px;
        }

        div {
          margin-top: 4px;
          font-size: 14px;
          font-weight: none;
          text-align: center;
          color: #fff;
        }
        .username {
          max-width: 80px;
          margin: 10px auto;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .wpj {
        width: 48%;
        height: 100px;
        background-image: url("../../assets/images/watchPk/playerbg.png");
        background-size: 100% 100%;

        img {
          display: block;
          margin: 12px auto;
          width: 55px;
          height: 55px;
        }
      }
    }
  }
}
</style>
